<template>
  <div class="operationLog">
    <!-- 头部区域 -->
    <div class="header flexbox">
      <el-date-picker
        v-model="time"
        class="mr20"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetimerange"
        range-separator="至"
        start-placeholder="操作开始时间"
        end-placeholder="操作结束时间">
      </el-date-picker>
      <el-select v-model="form.type" class="mr20" placeholder="操作类型" clearable>
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="form.status" class="mr20" placeholder="操作状态" clearable>
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input v-model="form.nameOrPhone" class="inp mr20"  placeholder="姓名/手机号" clearable></el-input>
      <el-button type="primary" @click="operationLogQuery">搜索</el-button>
    </div>
    <!-- 内容区域 -->
    <div class="content">
      <PrevReclick rights="p_operation_log_export">
        <el-button type="primary" class="mb20" @click="operationLogExport">导出</el-button>
      </PrevReclick>
      <div class="table-box">
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column prop="id" label="序号" width="70" align="center"></el-table-column>
          <el-table-column prop="operationType" label="类型" align="center">
            <template slot-scope="scope">
              <span>{{typeOptions[scope.row.operationType].label}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="operationContent" label="操作内容" align="center"></el-table-column>
          <el-table-column prop="operator" label="操作人" align="center"></el-table-column>
          <el-table-column prop="phone" label="操作账号" align="center"></el-table-column>
          <el-table-column prop="createTime" label="时间" width="180" align="center">
            <template slot-scope="scope">
              <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="operationStatus" label="状态" align="center" >
            <template slot-scope="scope">
              <span>{{statusOptions[scope.row.operationStatus].label}}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt20" background :page-count="totalPage"
                       :current-page.sync="currentPage" @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { $sp_operationLogQuery, $sp_operationLogExport } from '@/api/operationLog';

export default {
  data() {
    return {
      totalPage: 1,
      currentPage: 1,
      form: {
        type: '',
        status: '',
        nameOrPhone: '',
      },
      time: '',
      typeOptions: [
        {
          label: '登录',
          value: 0,
        },
        {
          label: '新增',
          value: 1,
        },
        {
          label: '编辑',
          value: 2,
        },
        {
          label: '删除',
          value: 3,
        },
        {
          label: '授权',
          value: 4,
        },
        {
          label: '导出',
          value: 5,
        },
      ],
      statusOptions: [
        {
          label: '成功',
          value: 0,
        },
        {
          label: '失败',
          value: 1,
        },
      ],
      tableData: [],
    };
  },
  created() {
    this.operationLogQuery();
  },
  methods: {
    // 搜索
    operationLogQuery() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      params.startTime = this.time && this.time.length > 0 ? this.time[0] : '';
      params.endTime = this.time && this.time.length > 0 ? this.time[1] : '';
      $sp_operationLogQuery(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 导出
    operationLogExport() {
      const params = {
        ...this.form,
      };
      params.startTime = this.time && this.time.length > 0 ? this.time[0] : '';
      params.endTime = this.time && this.time.length > 0 ? this.time[1] : '';
      $sp_operationLogExport(params).then((res) => {
        console.log(res);
      });
    },
    // 翻页
    pageChange() {
      this.operationLogQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
.operationLog{
  // 头部区域
  .header{
    width:100%;
    padding:20px;
    box-sizing: border-box;
    background-color: #fff;
  }
  // 内容区域
  .content{
    width:100%;
    margin-top: 20px;
    background-color: #fff;
    padding:20px;
    box-sizing: border-box;
  }
  .inp{
    width: 200px;
  }
  .mb20{
    margin-bottom: 20px;
  }
}
</style>
